<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-menu-schedule-data-update") }}
        </h1>
        <div class="content-row">
            <div class="content-box sp-dc">
                <h2>스케줄 수정하기</h2>

                <div class="create-content" v-if="activeTab === 'Immediately'">
                    <div class="create-content-row">
                        <label>일정 제목<span>*</span></label>
                        <input type="text" placeholder="일정 제목을 입력해주세요." style="width: 85%;" v-model="scheduleName">
                    </div>
                    <div class="create-content-row">
                        <label>스테이션<span>*</span></label>
                        <input v-model="stationName" 
                            placeholder="스테이션을 선택해주세요" style="width: 500px; background-color: #e0e0e0;" disabled>
                    </div>
                    <div class="create-content-row">
                        <label>경로선택<span>*</span></label>
                        <div class="cm-input">
                            <select class="m-r-10" v-model="missionType" @change="missionName = ''">
                                <option value="WayPoint">웨이포인트</option>
                                <option value="Mapping">맵핑</option>
                            </select>
                            <input v-model="missionName" @click="isMissionModal = true"
                                placeholder="경로를 선택해주세요">
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>시작 날짜<span>*</span></label>
                        <div class="cd-input">
                            <input type="datetime-local" style="width: 500px;" disabled>
                            <h4>현재 시간 3분 후에 즉시 비행이 시작됩니다.&#40;선택된 날짜만 비행합니다.&#41;</h4>
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>주의사항<span>*</span></label>
                        <textarea v-model="desc" cols="50" rows="10" maxlength="200"
                            placeholder="최대 200자 내로 적어주세요."></textarea>
                    </div>
                </div>

                <!-- 예약 tab -->
                <div class="create-content" v-if="activeTab === 'Reserve'">
                    <div class="create-content-row">
                        <label>일정 제목<span>*</span></label>
                        <input type="text" placeholder="일정 제목을 입력해주세요." style="width: 85%;" v-model="scheduleName">
                    </div>
                    <div class="create-content-row">
                        <label>스테이션<span>*</span></label>
                        <input v-model="stationName" 
                            placeholder="스테이션을 선택해주세요" style="width: 500px; background-color: #e0e0e0;" disabled>
                    </div>
                    <div class="create-content-row">
                        <label>경로선택<span>*</span></label>
                        <div class="cm-input">
                            <select class="m-r-10" v-model="missionType" @change="missionName = ''">
                                <option value="WayPoint">웨이포인트</option>
                                <option value="Mapping">맵핑</option>
                            </select>
                            <input v-model="missionName" @click="isMissionModal = true"
                                placeholder="경로를 선택해주세요">
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>작동시간<span>*</span></label>
                        <div class="cd-input">
                            <select class="m-r-10" style="width: 80px;" v-model="times.amPm">
                                <option value="오전">오전</option>
                                <option value="오후">오후</option>
                            </select>
                            <select class="m-r-10" style="width: 100px;" v-model="times.hour">
                                <option v-for="n in 12" :key="n" :value="String(n)">{{ n }}시</option>
                            </select>
                            <input type="number" min="0" max="59" style="width: 100px;" v-model="times.minute">
                            <span>분</span>
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>시작날짜<span>*</span></label>
                        <div class="cd-input">
                            <Datepicker v-model="beginLocalDate" :language="ko" :format="customFormatter"
                                placeholder="YYYY-MM-DD" style="border-radius: 5px;margin-right:10px;width:300px;">
                            </Datepicker>
                            <h4>선택된 날짜만 비행합니다.</h4>
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>주의사항<span>*</span></label>
                        <textarea v-model="desc" cols="50" rows="10" maxlength="200"
                            placeholder="최대 200자 내로 적어주세요."></textarea>
                    </div>
                </div>

                <!-- 반복 tab -->
                <div class="create-content" v-if="activeTab === 'Period'">
                    <div class="create-content-row">
                        <label>일정 제목<span>*</span></label>
                        <input type="text" placeholder="일정 제목을 입력해주세요." style="width: 85%;" v-model="scheduleName">
                    </div>
                    <div class="create-content-row">
                        <label>스테이션<span>*</span></label>
                        <input v-model="stationName" 
                            
                            placeholder="스테이션을 선택해주세요" style="width: 500px; background-color: #e0e0e0;" disabled>
                    </div>
                    <div class="create-content-row">
                        <label>경로선택<span>*</span></label>
                        <div class="cm-input">
                            <select class="m-r-10" v-model="missionType" @change="missionName = ''">
                                <option value="WayPoint">웨이포인트</option>
                                <option value="Mapping">맵핑</option>
                            </select>
                            <input v-model="missionName" @click="isMissionModal = true"
                                placeholder="경로를 선택해주세요">
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>작동시간<span>*</span></label>
                        <div class="cd-input">
                            <select class="m-r-10" style="width: 80px;" v-model="times.amPm">
                                <option value="오전">오전</option>
                                <option value="오후">오후</option>
                            </select>
                            <select class="m-r-10" style="width: 100px;" v-model="times.hour">
                                <option v-for="n in 12" :key="n" :value="String(n)">{{ n }}시</option>
                            </select>
                            <input type="number" min="0" max="59" style="width: 100px;" v-model="times.minute">
                            <span>분</span>
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>시작날짜<span>*</span></label>
                        <div class="cd-input">
                            <Datepicker v-model="beginLocalDate" :language="ko" :format="customFormatter"
                                placeholder="YYYY-MM-DD" style="border-radius: 5px;margin-right:10px;width:300px;">
                            </Datepicker>
                            <h4>선택된 날짜만 비행합니다.</h4>
                        </div>
                    </div>
                    <div class="create-content-row">
                        <label>종료날짜<span>*</span></label>
                        <div class="cd-input">
                            <Datepicker v-model="endLocalDate" :language="ko" :format="customFormatter"
                                placeholder="YYYY-MM-DD" style="border-radius: 5px;margin-right:10px;width:300px;">
                            </Datepicker>
                        </div>
                    </div>
                    <div class="create-content-row" style="align-items: center">
                        <label>요일선택<span>*</span></label>
                        <ul>
                            <li v-for="(day, index) in days" :key="day">
                                <input type="checkbox" :value="index + 1" v-model="repeatDays"
                                    class="m-r-10">
                                <span>{{ day }}요일</span>
                            </li>
                        </ul>
                    </div>
                    <div class="create-content-row">
                        <label>주의사항<span>*</span></label>
                        <textarea v-model="desc" cols="50" rows="10" maxlength="200"
                            placeholder="최대 200자 내로 적어주세요."></textarea>
                    </div>
                </div>


                <div class="buttonWrap"
                    style="display: flex; margin-top: 15px; justify-content: center;margin-bottom:15px;">
                    <button class="point medium" @click="updateSchedule"
                        style="width: 150px;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;margin-right:0;">
                        {{ $t("btn-data-patch") }}
                    </button>
                    <button class="medium margin6" @click="deleteSchedule"
                        style="width: 150px;height:40px; border:1px solid #ddd;color:#969696;color:#969696; font-weight:400;border-radius:5px;">
                        {{ $t("btn-delete") }}
                    </button>
                    <button class="medium margin6" @click="cancleUpdate"
                        style="width: 150px;height:40px; border:1px solid #ddd;color:#969696; font-weight:400;border-radius:5px;">
                        {{ $t("btn-cancel") }}
                    </button>
                </div>
            </div>
        </div>


        <!-- way modal -->
        <div v-if="isMissionModal" class="modal-mask">
            <!-- 경로팝업 컴포넌트 호출 -->
            <MissionModal :eventId="this.eventId" :missionType="missionType" :missionId="this.missionId" :refId="stationId" @isMissionModal="isMissionModal = false"
                @saveMission="saveMission" />
        </div>
    </div>
</template>

<style>
.title {
    display: none;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.tabs {
    margin-bottom: 15px;
}

.tabs>.tab {
    width: 150px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #969696;
    font-size: 1.2rem;
}

.tabs>.tab.active {
    border: 1px solid #0080ff;
    color: #0080ff;
}

.content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.sp-dc {
    width: 100%;
}

.sp-create>h2 {
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.create-content {
    width: 100%;
    height: 600px;
    max-height: 600px;
    overflow-y: auto;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.create-content-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.create-content-row>label {
    width: 15%;
    font-size: 1.2rem;
    line-height: 35px;
}

.create-content-row>label>span {
    margin-left: 3px;
    color: #ff3838;
}

.create-content-row>input {
    width: 85%;
    margin: 0;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #1e1e1e;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
}

.create-content-row>.cm-input>input {
    margin: 0;
    width: 330px;
    margin-right: 10px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #1e1e1e;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
}

.create-content-row>.cm-input>select {
    margin: 0;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #1e1e1e;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
}

.create-content-row>.cd-input>input {
    margin: 0;
    width: 330px;
    margin-right: 10px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #1e1e1e;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
}

.create-content-row>.cd-input>input:disabled {
    background-color: #eeeeee;
    font-family: "INTER", "Malgun Gothic", sans-serif;
}

.create-content-row>.cd-input>select {
    margin-right: 10px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    color: #1e1e1e;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
}

.create-content-row>.cd-input>h4 {
    color: #ff3838;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 5px;
}

.create-content-row>select {
    width: 150px;
    border-radius: 5px;
    height: 35px;
    cursor: pointer;
    margin-right: 10px;
}

.create-content-row>textarea {
    width: 85%;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
}

.create-content-row>textarea:focus-visible {
    outline: 1px solid #0790ff;
}

.vdp-datepicker>div>input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 0;
}

.create-content-row>ul {
    display: flex;
    align-items: center;
}

.create-content-row>ul>li {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.cr-data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.cr-data>input {
    margin-bottom: 0;
}

.cr-data-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cr-data-btn>button {
    font-size: 1.2rem;
    background: #eee;
    border: 0;
    border-radius: 5px;
    padding: 0 20px;
    color: #969696;
    margin-right: 10px;
}

.cr-data-btn>button.active {
    background: #0080ff;
    color: #fff;
}

.cr-sel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cr-sel>select {
    border-radius: 5px;
    height: 35px;
    cursor: pointer;
    margin-right: 10px;
}

.cr-sel>button {
    color: #0080ff;
    border: 0;
    font-size: 1.4rem;
}

/* 모달 스타일 */
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-wrapper {
    width: auto;
}

.modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.modal-title>h3 {
    font-size: 1.6rem;
}

.modal-title>button {
    border: 0;
}

.modal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 15px;
}

.modal-write {
    display: flex;
}

.modal-write>.info-box {
    margin-right: 20px;
}

.modal-write>.info-box>span:nth-child(1) {
    color: #969696;
    background: #eee;
    padding: 8px 15px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 1.2rem;
}

.modal-write>.info-box>span:nth-child(2) {
    font-size: 1.4rem;
}

.modal-info>h3 {
    font-size: 1.4rem;
    font-weight: 300;
}

.modal-info>h3>span {
    color: #0080FF;
    margin-left: 5px;
}

.modal-timeLine {
    position: relative;
    height: 450px;
    max-height: 450px;
    overflow-y: auto;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

.modal-timeLine::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #62b1ff;
    left: 50px;
}

.modal-timeLine>li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-timeLine>li>.line-mark {
    position: relative;
    width: 100px;
}

.modal-timeLine>li>.line-mark::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    background: #0080ff;
    left: 46px;
    border-radius: 10px;
    top: 5px;
    z-index: 100;
}

.modal-timeLine>li>.line-mark::after {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid #0080ff;
    border-radius: 10px;
    left: 41px;
}

.modal-timeLine>li>.timeLine-box {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    background: #f1f2f6;
    border-radius: 5px;
}

.modal-timeLine>li>.timeLine-box>span {
    font-size: 1.2rem;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list {
    margin-bottom: 15px;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h3 {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h5 {
    font-size: 1.2rem;
    color: #969696;
    font-weight: 400;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h5>span {
    margin-left: 10px;
    font-size: 1.2rem;
    background: #0080ff;
    padding: 2px 10px;
    border-radius: 10px;
    color: #fff;
}

.modal-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.modal-search {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.modal-search>span {
    color: #969696;
    background: #eee;
    padding: 8px 15px;
    border-radius: 5px;
    margin-right: 10px;
}

.modal-search>select {
    background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;
    width: 110px;
    border: 1px solid #ddd;
    height: 32px;
    padding: 5px 25px 5px 12px;
    border-radius: 5px;
    font-size: 1.2rem;
    color: #fff;
}

.modal-search>select:focus-visible {
    outline: 1px solid #2B79EE;
}

.modal-search>select>option {
    background: #ffffff;
    color: #969696;
}

.modal-search>input {
    margin: 0;
    background: #ffffff;
    border: 0;
    margin-left: 5px;
    color: #000;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.modal-search>input:hover {
    border: 1px solid #ddd;
}

.modal-search>input:focus-visible {
    outline: 1px solid #2B79EE;
}

.modal-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.modal-content>.map {
    width: 450px;
    height: 400px;
    margin-right: 20px;
}

.modal-list {
    width: 300px;
}

.modal-list>ul {
    height: 363px;
    max-height: 363px;
    overflow-y: auto;
}

.modal-list>ul>li {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-list>ul>li>h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.modal-list>ul>li>p {
    font-size: 1.2rem;
    color: #969696;
}

.modal-list>ul>li>p>img {
    margin-right: 5px;
}

.modal-list>ul>li.active {
    border: 1px solid #0080FF;
    background: #f7fbff;
}

.modal-list>ul>li.active>h4 {
    color: #0080FF;
}

.modal-list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.modal-list-title>h4 {
    font-size: 1.2rem;
    color: #969696;
    font-weight: 400;
}

.modal-list-title>h5 {
    font-size: 1.2rem;
    font-weight: 400;
}

.modal-list-title>h5>span {
    margin-left: 5px;
    color: #0080ff;
}

.modal-btn {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.modal-btn>button {
    margin: 0 5px;
    width: 120px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #969696;
    font-size: 1.4rem;
}

.modal-btn>button.btn-save {
    background: #0080ff;
    color: #fff;
    border: 1px solid #0080ff;
}

.buttonWrap {
    margin: 0;
}

.buttonWrap>button {
    padding: 2px 27px;
    border: 0;
    font-weight: 300;
    color: #fff;
    border-radius: 5px;
}

.buttonWrap>button:nth-child(1) {
    background: #0080FF;
    margin-right: 8px;
}

.buttonWrap>button:nth-child(2) {
    background: #fff;
    color: #969696;
    border: 1px solid #ddd;
}
</style>


<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import MissionModal from '@/components/schedule/MissionModal.vue';
import { updateStationSchedule, fetchStationScheduleDetail, deleteStationSchedule } from "@/api/station/stationSchedule";

export default {
    name: "SchedulePlanUpdate",
    components: {
        Datepicker,
        MissionModal
    },
    props: {
        // activeTab: {
        //     type: String,
        //     required: true
        // },
        // scheduleId: {
        //     typeof: String,
        //     required: true
        // }
    },
    data() {
        return {
            ko: ko,
            eventId: this.$route.params.eventId,
            isMissionModal: false,
            days: ['월', '화', '수', '목', '금', '토', '일'],
            scheduleId: null,
            missionType: "WayPoint",
            resquestSchedule: null,
            currentTime: '',
            scheduleName:"",
            beginLocalDate:"",
            endLocalDate: null,
            activeTab: null,
            times: {
                amPm: '',
                hour: 0,
                minute: 0,
            },
            stationName: "",
            stationId : "",
            missionName: "",
            missionId: "",
            repeatDays: null,
            desc: "",
            isRepeat: null,
            
        }
    },
    created() {
        this.scheduleId = this.$route.params.scheduleId
        this.getScheduleInfo()
    },
    mounted() {
        if(this.activeTab === 'Immediately') {
            alert('즉시는 수정이 불가능합니다. 이전페이지로 돌아갑니다.')
            this.$router.go(-1)
        }
        if(this.$route.params.scheduleId === null) {
            alert('선택된 스케쥴이 없습니다. 이전페이지로 돌아갑니다.')
            this.$router.go(-1)
        }
    },
    methods: {
        cancleUpdate(){
            this.scheduleId = null
            this.$router.go(-1)
        },
        getScheduleInfo() {
            fetchStationScheduleDetail(this.scheduleId)
                .then(res => {
                    const datas = res.data.data

                    this.resquestSchedule = datas

                    this.activeTab = datas.scheduleType
                    this.scheduleName = datas.scheduleName
                    this.beginLocalDate = datas.beginLocalDate
                    this.endLocalDate = datas.endLocalDate 
                    this.desc = datas.desc
                    this.stationName = datas.item.stationDevice.deviceName
                    this.stationId = datas.item.stationDevice.stationDeviceId
                
                    this.missionType = datas.item.refJobType
                    this.missionName = datas.item.missionTitle
                    this.missionId = datas.item.missionId
                    this.repeatDays = datas.weekValues !== null ? datas.weekValues.split(',').map(Number) : null
                    this.times = this.createTimeFormatteObject(datas.localTime)
                    this.isRepeat = datas.isRepeat
                    
                })
        },
        

        createTimeFormatteObject(time){
            const times = {
                amPm : moment(time,"HH:mm").hours() >= 12 ? '오후' : '오전',
                hour :  moment(time,"HH:mm").hours() % 12 || 12,
                minute : moment(time,"HH:mm").minute()
            }
            return times
        },

        customFormatter(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        timeObjToString(times) {
            let hours = Number(times.hour)
            const minute =`:${String(times.minute).padStart(2,'0')}`
            
            if(times.amPm === '오후' && hours !== 12){ 
                hours += 12
            } else if(times.amPm === '오전' && hours === 12) {
                hours = 0
            } 
            
            return `${String(hours).padStart(2,'0')}`+ minute + ':00'
        },

        updateSchedule() {
            this.resquestSchedule.scheduleName = this.scheduleName
            this.resquestSchedule.beginLocalDate = this.customFormatter(this.beginLocalDate)
            this.resquestSchedule.endLocalDate = this.customFormatter(this.endLocalDate)
            this.resquestSchedule.desc = this.desc
            this.resquestSchedule.item.refJobType = this.missionType
            this.resquestSchedule.item.missionTitle = this.missionName
            this.resquestSchedule.item.missionId = this.missionId
            this.resquestSchedule.item.scheduleName = this.scheduleName
            this.resquestSchedule.weekValues = this.repeatDays !== null ? this.repeatDays.sort((a, b) => { return a - b }).join(',') : null
            this.resquestSchedule.isRepeat =  this.isRepeat
            this.resquestSchedule.localTime = this.timeObjToString(this.times)

            updateStationSchedule(this.scheduleId, this.resquestSchedule).then((res)=> {
                if(res.status === 200) {
                    alert("수정완료했습니다.")
                    this.$router.push({ name: "SchedulePlan" })
                }
            })
        },

        deleteSchedule() {
            if(!this.isDeletable) {
                alert("10분전 스케줄은 수정이 불가합니다.")
                return
            }
            const isCheck = confirm("스케줄 삭제 및 수정 시 앞에 설정된 스케줄은 전부 삭제가 되고 다시 설정을 해주셔야 합니다.");
            if(!isCheck) { return }
            deleteStationSchedule(this.scheduleId).then((res)=> {
                if(res.status === 200) {
                    alert("전체삭제 완료~!")
                } else {
                    alert("실패")
                }
                this.$router.push({ name: "SchedulePlan" })
            })
            
        },

        isDeletable() {
            const currentTime = moment();
            const selectedTime = moment(`${this.resquestSchedule.localDate} ${this.resquestSchedule.localTime}`, "YYYY-MM-DD HH:mm:ss");
            const differenceInMinutes = selectedTime.diff(currentTime, 'minutes');
            return differenceInMinutes >= 10 ? true : false
        },

        saveMission(mission) {
            this.missionName = mission.title
            this.missionId = mission.missionId
            this.isMissionModal = false
        },
    }
}
</script>